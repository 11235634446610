@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: "Poppins", sans-serif;
  background-color: #f6f7f8;
  color: #333;
  overflow-x: hidden !important;
  max-width: 100% !important;
  scroll-behavior: smooth;
}

.container-item {
  max-width: 75%;
}
.carousel {
  display: flex;
  justify-content: center;
  height: fit-content !important;
}
.thumbs-wrapper {
  display: none;
}
.carousel .slide {
  height: 350px;
}

.carousel .thumb:hover {
  border: 2px solid #1d4ed8 !important;
}
.carousel .thumb.selected {
  border: 2px solid #1d4ed8 !important;
}
.carousel-status {
  display: none;
}
.carousel .image img {
  position: relative;
  margin: 0 auto;
  height: 350px !important;
  width: auto;
  object-fit: contain !important;
}
.carousel .control-dots .dot {
  margin-top: 15px !important;
  box-shadow: 1px 1px 2px rgb(179 72 72 / 90%);
  background: #1d4ed8 !important;
}
@media screen and (max-width: 768px) {
  .carousel .slide {
    height: 250px;
  }
  .carousel .image img {
    position: relative;
    margin: 0 auto;
    height: 250px !important;
    width: auto;
    object-fit: contain !important;
  }
}

.deneme {
  animation-duration: 2s;
  animation-name: efect;
  display: inline-block;
  overflow: hidden;
}
@keyframes efct {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.thumbnail {
  margin-left: 30px;
}
.clicked {
  border: 3px solid #1d4fd8cf;
}
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  left: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}
table,
th,
td {
  border: 1px solid #e9e7e7;
  padding: 6px 6px;
}
table {
  text-align: center;
  font-size: 10px;
  font-weight: 400;
}
.table-tr {
  background-color: #bfbfbf;
  color: #fff;
}
table tr:hover {
  background-color: #4c83eb;
  color: #fff;
}
